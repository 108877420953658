import { GET_STUDENT_LOGS } from "redux-thunk/constants/StoreArea";
import {
  GET_STUDENT_BY_ID,
  GET_STUDENT_DATA,
  GET_REGISTRED_STUDENT_LIST,
  GET_PROGRESS_DEFINITIONS_CATEGORIES,
  GET_PARENT_WIVES,
  GET_GRADES_WITH_PROGRESS_REPORT,
  GET_PARENT_STUDENTS,
  GET_ALL_PROGRESS_DEFINITIONS,
  GET_STUDENT_PROFILE,
  RESET_STUDENT_DATA,
  GET_PARENT_LIST,
  GET_STUDENT_DOCS,
  GET_STUDENT_DOCS_DETAILS,
  GET_PARENT_BYID,
  RESET_PARENT_DATA,
  GET_PARENT_DOCS,
  GET_PARENT_DOCS_DETAILS,
  GET_STUDENT_ATTENDANCE_TOTAL_VIEW_LIST,
  GET_STUDENT_MONTHLY_ATTENDANCE,
  GET_STUDENT_ABSENT_DATA,
  STUDENT_SEARCH,
  STUDENT_SEARCHR,
  REGISTRATIONSTUDENT_SEARCH,
  RESET_REGISTRATIONSTUDENT_SEARCH,
  GET_STUDENT_ACCOUNT_STATEMENT,
  RESET_GET_STUDENT_ACCOUNT_STATEMENT,
  GET_BULKFEES_STUDENTLIST,
  GET_BULKFEES_STUDENTLIST_FAIL,
  GET_STUDENT_EMP_GOVERNMENT_LIST,
  GET_STUDENT_ACCOUNT_STATEMENT_LIST,
  GET_STUDENT_ACCOUNT_STATEMENT_REVIEW_REQUEST_LIST,
  GET_PRE_REGISTRATION_STUDENT_LIST,
  RESET_STUDENT_BROTHER_LIST,
  GET_STUDENT_BROTHER_LIST,
  GET_STUDENT_PENDING_AMOUNT_LIST,
  GET_LEFT_STUDENT_LIST,
  GET_STUDENT_ACADEMICYEAR_HISTORY_LIST,
  RESET_GET_STUDENT_ACADEMICYEAR_HISTORY_LIST,
  GET_STUDENTS_WITHOUT_CLASS,
  GET_ALL_REQUEST_TYPES,
  GET_ALL_REQUESTES,
  GET_REQUESTES_SUMMARY,
  GET_ALL_HIGH_SCHOOL_TRACKS,
  GET_ALL_HIGH_SCHOOL_TRACKS_STUDENTS,
  GET_STUDENTS_WITH_SUBJECT_UNITS,
  GET_STUDENTS_WITH_SUBJECT_UNITS_MARKS,
  GET_STUDENTS_WITH_SUBJECTS_TOTAL_MARKS,
  GET_ALL_STUDENTS_BIHAVIOUR,
  GET_STUDENT_CERTIFICATION,
  GET_ALL_TEACHERS_WITH_SUBJECTS,
  GET_STUDENT_WITH_MAJOR,
  GET_MAJORS,
  GET_STUDENTS_WITH_SUBJECT_TEACHERS,
  HIGH_STUDENTS_REPORT_LIST,
  GET_REFUNDS,
  SET_CURRENT_STUDENT,
  GET_STUDENT_MEDICAL_SUMMARY,
  GET_ALL_STUDENT_MEDICAL_NOTES,
  GET_ALL_STUDENT_MEDICINE_LIST,
  GET_ALL_STUDENT_SURGERIES,
  GET_ALL_STUDENT_MEDICAL_EXAMS,
  GET_RETURENED_FEES,
  GET_STUDENT_LIBRARY_SUMMARY,
  GET_STUDENT_BOOKS_SUMMARY,
  GET_STUDENT_STORY_SUMMARY,
  GET_STUDENTS_WITH_MEDICAL_SUMMARY,
  GET_STUDENTS_WITH_MEDICAL_LEAVE,
  GET_STUDENTS_WITH_MEDICAL_LEAVE_TOTAL,
  GET_STUDENTS_WITH_LIBRARY_SUMMARY,
  RESET_STUDENT_LIBRARY_SEARCH_PARAMS,
  STUDENT_LIBRARY_PROFILE_SEARCH_PARAMS,
  RESET_STUDENT_MEDICAL_SEARCH_PARAMS,
  STUDENT_MEDICAL_PROFILE_SEARCH_PARAMS,
  GET_ALL_MEDICAL_VACANATIONS,
  GET_STUDENT_MEDICAL_VACANATIONS,
  GET_STUDENT_NOT_RETURNED_BOOKS,
  GET_FEEZ_NEXT_YEAR,
  GET_STUDENTS_WITHOUT_NEXT_ACDEMIC_YEAR,
  GET_CUMULATIVE_GPA,
  GET_STUDENT_WITH_MORE_NINTY_PECENT,
  GET_FINAL_GRADING_LIST_ELEMNTARY_OR_MIDDLE,
  GET_STUDENTS_COMULATIVE_GPA,
  GET_NEXT_YEAR_PROGRESS_DEFINITIONS,
  GET_STUDENTS_WITH_ZERO_FEEZ,
  GET_STUDENTS_WITHOUT_CODE,
  GET_KG_INTERVIEW_MODELS,
  GET_INTERVIEW_MODEL_BY_ID,
  GET_STUDENTS_STATUS,
  SET_STMENT_LIST_SEARCH
} from "../constants/StudentArea";
import { UPDATE_STUDENT_LIST_SUBJECT } from "redux-thunk/constants/AdminArea";

// locale

const initState = {
  studentListSearch: {
    size: 30,
    page: 1,
    name: "",
    civil_id: "",
    academic_year_id: null,
    school_grade_type_id: "",
    school_grade_id: [],
    school_class_id: [],
    home_number: "",
    status: null, // "0=Pending,1=Accept,2=Deactivate"
    sort_order: "ASC",
    order_by: "ar_name",
    parent_id: 0,
  },
  registredStudentListSearch: {
    size: 30,
    page: 1,
    name: "",
    civil_id: "",
    school_grade_type_id: "",
    school_grade_id: [],
    school_class_id: "",
    home_number: "",
    status: 1, // "0=Pending,1=Accept,2=Deactivate"
    sort_order: "ASC",
    order_by: "ar_name",
    parent_id: 0,
  },
  resetRegistrationSearch: false,

  studentList: [],
  studentListStatus: false,
  studentListMsg: "",
  studentListTotalItems: 0,
  studentListCurrentPage: 0,
  studentListOffset: 0,
  studentListLimit: 30,
  studentWithoutClass: [],
  // Registered Student List
  registerStudentList: [],
  registerStudentListStatus: false,
  registerStudentListMsg: "",
  registerStudentListTotalItems: 0,
  registerStudentListCurrentPage: 0,

  // get Student Documents by ID
  studentDocument: [],
  studentDocumentTotalItems: 0,
  studentDocumentCurrentPage: 0,
  studentDocumentDetail: [],

  studentData: {},
  studentDataStatus: false,
  studentProfileData: "",

  // Parents List Data
  parentsList: [],
  parentsListStatus: false,
  parentsListMsg: "",
  parentsListTotalItems: 0,
  parentsListCurrentPage: 0,

  parentData: [],
  parentDataStatus: false,
  parentDocument: [],
  parentDocumentTotalItems: 0,
  parentDocumentCurrentPage: 0,
  parentDocumentDetail: [],

  // student Attendance
  studentAttendanceList: [],

  // student Attendance
  studentAttendanceTotalViewList: [],
  studentAttendanceTotalViewListStatus: false,
  studentAttendanceTotalViewListMsg: "",
  studentAttendanceTotalViewListTotalItems: 0,
  studentAttendanceTotalViewListCurrentPage: 0,

  // Student Monthly Attendance List
  studentMonthlyAttendance: {},


  // single Student Absent Data
  studentAbsentData: [],

  parentStudentList: [],
  // for Student Account Fees,Student Fees History
  studentAccountStatement: [],
  studentAccountHistory: [],
  addedDiscount: "",

  // for Student Bulk Fees
  bulkFeesStudentList: [],
  bulkFeesStudentListStatus: false,
  bulkFeesStudentListMsg: "",
  bulkFeesStudentListTotalItems: 0,
  bulkFeesStudentListCurrentPage: 0,

  // For Student List for Bulk Discount only For EMP Student
  empGovStudentList: [],
  empGovStudentListStatus: false,
  empGovStudentListMsg: "",
  empGovStudentListTotalItems: 0,
  empGovStudentListCurrentPage: 0,

  // For Pre Register Student List For Next Year
  preRegisterStudentList: [],
  preRegisterStudentListStatus: false,
  preRegisterStudentListMsg: "",
  preRegisterStudentListTotalItems: 0,
  preRegisterStudentListCurrentPage: 0,

  // for student Account Statement All Student List
  studentAccountStatementList: [],
  studentAccountStatementListStatus: false,
  studentAccountStatementListMsg: "",
  studentAccountStatementListTotalItems: 0,
  studentAccountStatementListCurrentPage: 0,

  // for student studentAccountStatement ReviewRequests List
  studentAccountStatementReviewRequestsList: [],
  studentAccountStatementReviewRequestsListStatus: false,
  studentAccountStatementReviewRequestsListMsg: "",
  studentAccountStatementReviewRequestsListTotalItems: 0,
  studentAccountStatementReviewRequestsListCurrentPage: 0,

  // Brothers List
  studentBrotherList: [],

  // for Pending student fees for sms
  studentPendingFeesList: [],
  studentPendingFeesListStatus: false,
  studentPendingFeesListMsg: "",
  studentPendingFeesListTotalItems: 0,
  studentPendingFeesListCurrentPage: 0,

  // School Left Student List
  leftSchoolStudentList: [],
  leftSchoolStudentListStatus: false,
  leftSchoolStudentListMsg: "",
  leftSchoolStudentListTotalItems: 0,
  leftSchoolStudentListCurrentPage: 0,

  //Student Academic year History list
  studentAcademicYearistoryList: [],
  //Student Request type
  allRequestTypes: [],
  allSchoolGradesInAllYears: [],
  allRequests: [],
  requestesSummary: [],
  studentTracks: [],
  allTrackStudents: [],
  allProgressDefinitions: [],
  gradesWithProgressReport: [],
  progressDefinitionCategories: [],
  studentsListSubject: [],
  studWithSubjectUnitQuery:{},
  subjectsOfGrade: [],
  studentsListSubjectsAll: [],
  studentsListBehaviour: [],
  studentsListSubjectUnitMarks: [],
  //students certifications
  studentsCertifications: [],
  teachersSubjectsCertificates: [],
  studentsWithMajor: [],
  majors: [],
  totalStudentsWithMajor: 0,
  studentsSubjectTeacherList: [],
  subjectTeacherList: [],
  highStudentsReportsList: [],
  refunds: [],
  currentStudent: null,
  medicalSummary: {},
  medicalNotes: [],
  medicineList: [],
  surgeryList: [],
  medicalExamList: [],
  returnedFees: [],
  librarySummary: [],
  studentBooks: [],
  studentStories: [],
  studentsWithMedicalSummary: [],
  studentsWithLibrarySummary: [],
  studentLibrarySearchParams: {
    page: 1,
    size: 30000,
    name: "",
    // civil_id: "",
    // school_grade_type_id: "",
    school_grade_id: "",
    school_class_id: "",
    // home_number: "",
    // status: "",
    // parent_id: "",

  },
  studentMedicalSearchParams: {
    page: 1,
    size: 30000,
    name: "",
    civil_id: "",
    school_grade_type_id: null,
    school_grade_id: null,
    school_class_id: null,
    home_number: "",
    status: "1,2,3",
    parent_id: "",
  },
  medicalVaccinations: [],
  studentMedicalVaccinations: [],
  studentNotReturnedBooks: [],
  studentFeezNextYear: [],
  studentWithountNextAcademicYear: [],
  studentCumulativeGPA:[],
  studentWithMoreNintyPercent:[],
  middleOrElementaryFinalGradingList:[],
  studentsComulativeGPA:[],
  nextYearProgressDefinitions:[],
  studentsWithZeroFeezPaid:[],
  studentsWithoutCode:[],
  kgInterviewModels:{},
  interViewModel:{},
  studentsStatus:{},
  parentWives:[],
  initialStatmentSearch:{
    size: 30000,
    page: 1,
    student_name: '',
    student_code: '',
    receipt_no:'',
    // civil_id: '',
    school_grade_id: null,
    school_class_id: null,
    phone_number: '',
    sort_order: 'ASC',
    order_by: localStorage.getItem('locale') === 'ar' ? 'ar_name' : 'name',
    start_date: '',
    end_date: '',
    academic_year_id: null
  },
  studentLogs:{}
};

const StudentArea = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STUDENT_SEARCH:
      return {
        ...state,
        studentListSearch: payload,
      };
    case STUDENT_SEARCHR:
      return {
        ...state,
        registredStudentListSearch: payload,
      };
    case REGISTRATIONSTUDENT_SEARCH:
      return {
        ...state,
        registredStudentListSearch: payload,
        resetRegistrationSearch: true,
      };
    case RESET_REGISTRATIONSTUDENT_SEARCH:
      return {
        ...state,
        registredStudentListSearch: {
          size: 30,
          page: 1,
          name: "",
          civil_id: "",
          school_grade_type_id: "",
          school_grade_id: [],
          school_class_id: [],
          home_number: "",
          status: 1, // "0=Pending,1=Accept,2=Deactivate"
          sort_order: "DESC",
          order_by: "ar_name",
          parent_id: 0,
        },
        resetRegistrationSearch: false,
      };

    case GET_STUDENT_DATA:
      return {
        ...state,
        // schoolYear: payload
        studentList: payload?.data?.result || [],
        studentListStatus: payload?.status || false,
        studentListMsg: payload?.msg || "Something Went Wrong",
        studentListTotalItems: payload?.data?.totalItems || 0,
        studentListCurrentPage: payload?.data?.currentPage || 1,
        studentListOffset: payload?.data?.offset || 0,
        studentListLimit: payload?.data?.limit || 60,
      };
    case GET_REGISTRED_STUDENT_LIST:
      return {
        ...state,
        registerStudentList: payload?.data?.result || [],
        registerStudentListStatus: payload?.status || false,
        registerStudentListMsg: payload?.msg || "Something Went Wrong",
        registerStudentListTotalItems: payload?.data?.totalItems || 0,
        registerStudentListCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_STUDENT_BY_ID:
      return {
        ...state,
        studentData: payload?.data || {},
        studentDataStatus: payload?.status || false,
      };

    case GET_STUDENT_PROFILE:
      return {
        ...state,
        studentProfileData: payload?.data || "",
      };
    case RESET_STUDENT_DATA:
      return {
        ...state,
        studentData: {},
        studentProfileData: {},
        studentDataStatus: false,
      };
    case GET_STUDENTS_WITHOUT_CLASS:
      return {
        ...state,
        studentWithoutClass: payload?.data?.result || [],
      };
    case GET_PARENT_LIST:
      return {
        ...state,
        // schoolYear: payload
        parentsList: payload?.data?.result || [],
        parentsListStatus: payload?.status || false,
        parentsListMsg: payload?.msg || "Something Went Wrong",
        parentsListTotalItems: payload?.data?.totalItems || 0,
        parentsListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_STUDENT_DOCS:
      return {
        ...state,
        studentDocument: payload?.data?.result || [],
        studentDocumentTotalItems: payload?.data?.totalItems || 0,
        studentDocumentCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_STUDENT_DOCS_DETAILS:
      return {
        ...state,
        studentDocumentDetail: payload?.data || [],
      };

    case GET_PARENT_BYID:
      return {
        ...state,
        parentData: payload?.data ? payload?.data : [],
        parentDataStatus: payload?.status || false,
      };

    case RESET_PARENT_DATA:
      return {
        ...state,
        parentData: [],
        parentDataStatus: false,
      };

    case GET_PARENT_DOCS:
      return {
        ...state,
        parentDocument: payload?.data?.result || [],
        parentDocumentTotalItems: payload?.data?.totalItems || 0,
        parentDocumentCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_PARENT_DOCS_DETAILS:
      return {
        ...state,
        parentDocumentDetail: payload?.data || [],
      };


    case GET_STUDENT_ATTENDANCE_TOTAL_VIEW_LIST:
      return {
        ...state,
        studentAttendanceTotalViewList: payload?.data?.result || [],
        studentAttendanceTotalViewListStatus: payload?.status || false,
        studentAttendanceTotalViewListMsg:
          payload?.msg || "Something Went Wrong",
        studentAttendanceTotalViewListTotalItems:
          payload?.data?.totalItems || 0,
        studentAttendanceTotalViewListCurrentPage:
          payload?.data?.currentPage || 0,
      };

    case GET_STUDENT_MONTHLY_ATTENDANCE:
      return {
        ...state,
        studentMonthlyAttendance: payload?.data || {},
      };
    case GET_STUDENT_ABSENT_DATA:
      return {
        ...state,
        studentAbsentData: payload?.data || [],
      };
    case GET_PARENT_STUDENTS:
      return {
        ...state,
        parentStudentList: payload?.data || [],
      };

    case GET_STUDENT_ACCOUNT_STATEMENT:
      return {
        ...state,
        studentAccountStatement: payload?.data || [],
        studentAccountHistory: payload?.student_history || [],
        addedDiscount: payload?.student_discount
          ? payload?.student_discount[0]
          : [] || [],
      };
    case RESET_GET_STUDENT_ACCOUNT_STATEMENT:
      return {
        ...state,
        studentAccountStatement: [],
        studentAccountHistory: [],
        addedDiscount: [],
      };
    case GET_BULKFEES_STUDENTLIST:
      var newData = [];
      if (payload?.data?.result?.length > 0) {
        for (let i = 0; i < payload?.data?.result?.length; i++) {
          newData.push({
            uniqueKey: i + 1,
            ...payload.data.result[i],
          });
        }
      }
      return {
        ...state,
        // bulkFeesStudentList: payload?.data?.result || [],
        bulkFeesStudentList: newData || [],
        bulkFeesStudentListStatus: payload?.status || false,
        bulkFeesStudentListMsg: payload?.msg || "Something Went Wrong",
        bulkFeesStudentListTotalItems: payload?.data?.totalItems || 0,
        bulkFeesStudentListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_BULKFEES_STUDENTLIST_FAIL:
      return {
        ...state,
        bulkFeesStudentList: [],
        bulkFeesStudentListStatus: false,
        bulkFeesStudentListMsg: "",
        bulkFeesStudentListTotalItems: 0,
        bulkFeesStudentListCurrentPage: 0,
      };

    case GET_STUDENT_EMP_GOVERNMENT_LIST:
      return {
        ...state,
        empGovStudentList: payload?.data?.result || [],
        empGovStudentListStatus: payload?.status || false,
        empGovStudentListMsg: payload?.msg || "Something Went Wrong",
        empGovStudentListTotalItems: payload?.data?.totalItems || 0,
        empGovStudentListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_PRE_REGISTRATION_STUDENT_LIST:
      return {
        ...state,
        preRegisterStudentList: payload?.data?.result || [],
        preRegisterStudentListStatus: payload?.status || false,
        preRegisterStudentListMsg: payload?.msg || "Something Went Wrong",
        preRegisterStudentListTotalItems: payload?.data?.totalItems || 0,
        preRegisterStudentListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_STUDENT_ACCOUNT_STATEMENT_LIST:
      return {
        ...state,
        studentAccountStatementList: payload?.data?.result || [],
        studentAccountStatementListStatus: payload?.status || false,
        studentAccountStatementListMsg: payload?.msg || "Something Went Wrong",
        studentAccountStatementListTotalItems: payload?.data?.totalItems || 0,
        studentAccountStatementListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_STUDENT_ACCOUNT_STATEMENT_REVIEW_REQUEST_LIST:
      return {
        ...state,
        studentAccountStatementReviewRequestsList: payload?.data?.result || [],
        studentAccountStatementReviewRequestsListStatus: payload?.status || false,
        studentAccountStatementReviewRequestsListMsg: payload?.msg || "Something Went Wrong",
        studentAccountStatementReviewRequestsListTotalItems: payload?.data?.totalItems || 0,
        studentAccountStatementReviewRequestsListCurrentPage: payload?.data?.currentPage || 0,
      };
    case RESET_STUDENT_BROTHER_LIST:
      return {
        ...state,
        studentBrotherList: [],
      };

    case GET_STUDENT_BROTHER_LIST:
      return {
        ...state,
        studentBrotherList: payload?.data?.result || [],
      };

    case GET_STUDENT_PENDING_AMOUNT_LIST:
      return {
        ...state,
        studentPendingFeesList: payload?.data?.result || [],
        studentPendingFeesListStatus: payload?.status || false,
        studentPendingFeesListMsg: payload?.msg || "Something Went Wrong",
        studentPendingFeesListTotalItems: payload?.data?.totalItems || 0,
        studentPendingFeesListCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_LEFT_STUDENT_LIST:
      return {
        ...state,
        leftSchoolStudentList: payload?.data?.result || [],
        leftSchoolStudentListStatus: payload?.status || false,
        leftSchoolStudentListMsg: payload?.msg || "Something Went Wrong",
        leftSchoolStudentListTotalItems: payload?.data?.totalItems || 0,
        leftSchoolStudentListCurrentPage: payload?.data?.currentPage || 0,
      };
    case RESET_GET_STUDENT_ACADEMICYEAR_HISTORY_LIST:
      return {
        ...state,
        studentAcademicYearistoryList: [],
      };
    case GET_STUDENT_ACADEMICYEAR_HISTORY_LIST:
      return {
        ...state,
        studentAcademicYearistoryList: payload?.data || [],
      };
    case GET_ALL_REQUEST_TYPES:
      return {
        ...state,
        allRequestTypes: payload?.data || [],
        allSchoolGradesInAllYears: payload?.school_grades || [],
      };
    case GET_ALL_REQUESTES:
      return {
        ...state,
        allRequests: payload?.data?.result || [],
        allRequestsTotalItems: payload?.data?.totalItems || 0
      };
    case GET_REQUESTES_SUMMARY:
      return {
        ...state,
        requestesSummary: payload?.data?.result || [],
      };
    case GET_ALL_HIGH_SCHOOL_TRACKS:
      return {
        ...state,
        studentTracks: payload ? payload : [],
      };
    case GET_ALL_HIGH_SCHOOL_TRACKS_STUDENTS:
      return {
        ...state,
        allTrackStudents: payload ? payload : [],
      };
    case GET_ALL_STUDENTS_BIHAVIOUR:
      return {
        ...state,
        studentsListBehaviour: payload ? payload?.data : [],
      };
    case GET_STUDENTS_WITH_SUBJECT_UNITS:
      return {
        ...state,
        subjectDefinition: payload ? payload?.data?.subjectDefinition : [],
        teacherSubjectMark: payload ? payload?.data?.teacherSubjectMark : [],
        gradingDefinition: payload ? payload?.data?.gradingDefinition : [],
        schoolSubjectsTeachersStudents: payload
          ? payload?.data?.schoolSubjectsTeachersStudents
          : [],
        schoolSubjectsTeachers: payload
          ? payload?.data?.schoolSubjectsTeachers
          : [],
        studentsListSubject: payload ? payload?.data?.students : [],
        studWithSubjectUnitQuery: payload ? payload?.query : []
      };
    case GET_STUDENTS_WITH_SUBJECT_UNITS_MARKS:
      return {
        ...state,
        studentsListSubjectUnitMarks: payload ? payload : [],
      };

    case GET_STUDENTS_WITH_SUBJECTS_TOTAL_MARKS:
      return {
        ...state,
        subjectsOfGrade: payload ? payload?.data?.subjectOfGrade : [],
        gradingDefinition: payload ? payload?.data?.gradingDefinition : [],
        studentsListSubjectsAll: payload ? payload?.data?.students : [],
      };
    case UPDATE_STUDENT_LIST_SUBJECT:
      return{
        ...state,
        studentsListSubject: payload || []
      }
    case GET_ALL_PROGRESS_DEFINITIONS:
      return {
        ...state,
        allProgressDefinitions: payload ? payload : [],
      };
    case GET_NEXT_YEAR_PROGRESS_DEFINITIONS:
      return {
        ...state,
        nextYearProgressDefinitions: payload ? payload : [],
      };
      
    case GET_GRADES_WITH_PROGRESS_REPORT:
      return {
        ...state,
        gradesWithProgressReport: payload ? payload.data.result : [],
      };
    case GET_PROGRESS_DEFINITIONS_CATEGORIES: {
      return {
        ...state,
        progressDefinitionCategories: payload ? payload.data.result : [],
      };
    }
    case GET_STUDENT_CERTIFICATION: {
      return {
        ...state,
        studentsCertifications: payload ? payload.data : [],
      };
    }
    case GET_ALL_TEACHERS_WITH_SUBJECTS: {
      return {
        ...state,
        teachersSubjectsCertificates: payload ? payload.data : [],
      };
    }
    case GET_STUDENT_WITH_MAJOR: {
      return {
        ...state,
        studentsWithMajor: payload ? payload.data.result : [],
        totalStudentsWithMajor: payload ? payload.data.totalItems : 0,
      };
    }
    case GET_MAJORS: {
      return {
        ...state,
        majors: payload ? payload.data : [],
      };
    }
    case GET_REFUNDS: {
      return {
        ...state,
        refunds: payload ? payload.data?.result : [],
      };
    }
    case GET_STUDENTS_WITH_SUBJECT_TEACHERS: {
      return {
        ...state,
        studentsSubjectTeacherList: payload ? payload.data?.students : [],
        subjectTeacherList: payload ? payload.data?.teachers : [],
      };
    }
    case HIGH_STUDENTS_REPORT_LIST: {
      return {
        ...state,
        highStudentsReportsList: payload ? payload.data?.result : [],
      };
    }
    case SET_CURRENT_STUDENT: {
      return {
        ...state,
        currentStudent: payload,
      };
    }
    case GET_STUDENT_MEDICAL_SUMMARY: {
      return {
        ...state,
        medicalSummary: payload?.data || {},
      };
    }
    case GET_ALL_STUDENT_MEDICAL_NOTES: {
      return {
        ...state,
        medicalNotes: payload?.data || [],
      };
    }
    case GET_ALL_STUDENT_MEDICINE_LIST: {
      return {
        ...state,
        medicineList: payload?.data || [],
      };
    }
    case GET_ALL_STUDENT_SURGERIES: {
      return {
        ...state,
        surgeryList: payload?.data || [],
      };
    }
    case GET_ALL_STUDENT_MEDICAL_EXAMS: {
      return {
        ...state,
        medicalExamList: payload?.data || [],
      };
    }
    case GET_RETURENED_FEES: {
      return {
        ...state,
        returnedFees: payload?.data?.result || [],
      };
    }
    case GET_STUDENT_LIBRARY_SUMMARY: {
      return {
        ...state,
        librarySummary: payload?.data || [],
      };
    }
    case GET_STUDENT_BOOKS_SUMMARY: {
      return {
        ...state,
        studentBooks: payload?.data || [],
      };
    }
    case GET_STUDENT_STORY_SUMMARY: {
      return {
        ...state,
        studentStories: payload?.data || [],
      };
    }
    case GET_STUDENTS_WITH_MEDICAL_SUMMARY: {
      return {
        ...state,
        studentsWithMedicalSummary: payload?.data || [],
      };
    }
    case GET_STUDENTS_WITH_MEDICAL_LEAVE_TOTAL: {
      return {
        ...state,
        studentsWithMedicalLeaveTotal: payload?.data || [],
      };
    }
    case GET_STUDENTS_WITH_MEDICAL_LEAVE: {
      return {
        ...state,
        studentsWithMedicalLeave: payload?.data || [],
      };
    }
    case GET_STUDENTS_WITH_LIBRARY_SUMMARY: {
      return {
        ...state,
        studentsWithLibrarySummary: payload?.data || [],
      };
    }
    case STUDENT_LIBRARY_PROFILE_SEARCH_PARAMS: {
      return {
        ...state,
        studentLibrarySearchParams: payload || {},
      };
    }
    case STUDENT_MEDICAL_PROFILE_SEARCH_PARAMS: {
      return {
        ...state,
        studentMedicalSearchParams: payload || {},
      };
    }
    case RESET_STUDENT_LIBRARY_SEARCH_PARAMS: {
      return {
        ...state,
        studentLibrarySearchParams: {
          page: 1,
          size: 30000,
          name: "",
          // civil_id: "",
          // school_grade_type_id: "",
          school_grade_id: "",
          school_class_id: "",
          // home_number: "",
          // status: "",
          // parent_id: "",
        },
      };
    }
    case RESET_STUDENT_MEDICAL_SEARCH_PARAMS: {
      return {
        ...state,
        studentMedicalSearchParams: {
          page: 1,
          size: 30000,
          name: "",
          civil_id: "",
          school_grade_type_id: "",
          school_grade_id: "",
          school_class_id: "",
          home_number: "",
          status: "1,2,3",
          parent_id: "",
        },
      };
    }
    case GET_ALL_MEDICAL_VACANATIONS: {
      return {
        ...state,
        medicalVaccinations: payload?.data || [],
      };
    }
    case GET_STUDENT_MEDICAL_VACANATIONS: {
      return {
        ...state,
        studentMedicalVaccinations: payload?.data || [],
      };
    }
    case GET_STUDENT_NOT_RETURNED_BOOKS: {
      return {
        ...state,
        studentNotReturnedBooks: payload?.data || [],
      };
    }
    case GET_FEEZ_NEXT_YEAR: {
      return {
        ...state,
        studentFeezNextYear: payload?.data?.result || [],
      };
    }
    case GET_STUDENTS_WITHOUT_NEXT_ACDEMIC_YEAR: {
      return {
        ...state,
        studentWithountNextAcademicYear: payload?.data?.result || [],
      };
    }
    case GET_CUMULATIVE_GPA: {
      return {
        ...state,
        studentCumulativeGPA: payload?.data || [],
      };
    }
    case GET_STUDENT_WITH_MORE_NINTY_PECENT: {
      return {
        ...state,
        studentWithMoreNintyPercent: payload?.data?.result || [],
      };
    }
    case GET_FINAL_GRADING_LIST_ELEMNTARY_OR_MIDDLE: {
      return {
        ...state,
        middleOrElementaryFinalGradingList: payload?.data?.result || [],
      };
    }
    case GET_STUDENTS_COMULATIVE_GPA: {
      return {
        ...state,
        studentsComulativeGPA: payload?.data || [],
      };
    }
    case GET_STUDENTS_WITH_ZERO_FEEZ: {
      return {
        ...state,
        studentsWithZeroFeezPaid: payload?.data?.result || [],
      };
    }
    case GET_STUDENTS_WITHOUT_CODE: {
      return {
        ...state,
        studentsWithoutCode: payload?.data || [],
      };
    }
    case GET_KG_INTERVIEW_MODELS: {
      return {
        ...state,
        kgInterviewModels: payload?.data || {},
      };
    }
    case GET_INTERVIEW_MODEL_BY_ID: {
      return {
        ...state,
        kgInterviewModel: payload?.data || {},
      };
    }
    case GET_STUDENTS_STATUS: {
      return {
        ...state,
        studentsStatus: payload?.data || {},
      };
    }
    case GET_PARENT_WIVES: {
      return {
        ...state,
        parentWives: payload?.data || [],
      };
    }
    case SET_STMENT_LIST_SEARCH: {
      return {
        ...state,
        initialStatmentSearch: payload || [],
      };
    }
    case GET_STUDENT_LOGS: {
      return {
        ...state,
        studentLogs: payload?.data || [],
      };
    }
    
    
    
    
    default:
      return state;
  }
};

export default StudentArea;
