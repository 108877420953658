import {
  DashboardOutlined,
  AppstoreOutlined,
  BulbOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { FaStethoscope } from 'react-icons/fa';
import { FaStoreAlt } from 'react-icons/fa';
import { GiPoloShirt } from 'react-icons/gi';
import { TbCertificate } from 'react-icons/tb';
import { CgListTree } from 'react-icons/cg';
import { RiPagesLine } from 'react-icons/ri';
import { GrSchedule } from 'react-icons/gr';
import { LiaPencilRulerSolid } from "react-icons/lia";
import { IoLibraryOutline } from "react-icons/io5";

const dashboardNavTree = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/apps`,
    title: 'dashboard',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'basic-data',
        path: `${APP_PREFIX_PATH}/apps/`,
        title: 'admin.area',
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'school-data',
            path: `${APP_PREFIX_PATH}/admin/school-data`,
            title: 'school.data',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: 'role',
          //   path: `${APP_PREFIX_PATH}/admin/role`,
          //   title: 'Role',
          //   icon: '',
          //   breadcrumb: false,
          //   submenu: []
          // },
          {
            key: 'doc-type',
            path: `${APP_PREFIX_PATH}/admin/doc-type`,
            title: 'Doc.Type',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'adminFees',
            path: `${APP_PREFIX_PATH}/admin/adminFees`,
            title: 'Student.Fees',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-dynamic-report',
            path: `${APP_PREFIX_PATH}/dynamic-report/student-dynamic-report`,
            title: 'Dynamic.Reports.For.Students',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-dynamic-report',
            path: `${APP_PREFIX_PATH}/dynamic-report/employee-dynamic-report`,
            title: 'Dynamic.Reports.For.Employees',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'permission-group',
            path: `${APP_PREFIX_PATH}/admin/group/permission-group`,
            title: 'lms.roles.group.permission.group',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'assign-group',
            path: `${APP_PREFIX_PATH}/admin/group/assign-group`,
            title: 'lms.roles.group.assign.group',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'school-departments',
            path: `${APP_PREFIX_PATH}/admin/school-departments`,
            title: 'school.departments',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'app-icons',
            path: `${APP_PREFIX_PATH}/admin/app-icons`,
            title: 'App.Icons',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'transfare-school-system',
            path: `${APP_PREFIX_PATH}/admin/transfare-school-system`,
            title: 'transfare.school.system',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'school-year-setting',
            path: `${APP_PREFIX_PATH}/admin/school-year-setting`,
            title: 'General_Setting',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'grading-system-config',
        path: `${APP_PREFIX_PATH}/apps/`,
        title: 'grading.config',
        icon: TbCertificate,
        breadcrumb: true,
        submenu: [
          {
            key: 'create-grading-system',
            path: `${APP_PREFIX_PATH}/grading-system-config/create-grading-system`,
            title: 'Create.Grading.System',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'school-system-config',
            path: `${APP_PREFIX_PATH}/grading-system-config/school-system-config`,
            title: 'grading.grading_system_definition',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'school-subject-assessments',
            path: `${APP_PREFIX_PATH}/grading-system-config/school-subject-assessments`,
            title: 'grading.assessments',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'assesment-chart',
            path: `${APP_PREFIX_PATH}/grading-system-config/assesment-chart`,
            title: 'Assesment Chart',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'detailed-assesment-chart',
            path: `${APP_PREFIX_PATH}/grading-system-config/detailed-assesment-chart`,
            title: 'Detailed_Assesment_Chart',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'detailed-assesment-chart-elective',
            path: `${APP_PREFIX_PATH}/grading-system-config/detailed-assesment-chart-elective`,
            title: 'Detailed_Assesment_Chart_Elective',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'skills-chart',
            path: `${APP_PREFIX_PATH}/grading-system-config/skills-chart`,
            title: 'Skills.Chart',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'teachers-subjects-certificate',
            path: `${APP_PREFIX_PATH}/grading-system-config/teachers-subjects-certificate`,
            title: 'teachers.subjects.certificate',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'apply-student-teacher',
            path: `${APP_PREFIX_PATH}/grading-system-config/apply-student-teacher`,
            title: 'apply.student.teacher',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'confirmed-subject-marks',
            path: `${APP_PREFIX_PATH}/grading-system-config/confirmed-subject-marks`,
            title: 'Confirmed.Subjects',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'grades-study-start-date',
            path: `${APP_PREFIX_PATH}/grading-system-config/grades-study-start-date`,
            title: 'study.start.date',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'final-grading-listing',
            path: `${APP_PREFIX_PATH}/grading-system-config/final-grading-listing`,
            title: 'Final.Grading.Listing',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'KG',
            path: `${APP_PREFIX_PATH}/grading-system-config`,
            title: 'KG',
            icon: '',
            parentKey: 'grading-system-config',
            breadcrumb: false,
            submenu: [
              {
                key: 'kg-report-card',
                path: `${APP_PREFIX_PATH}/grading-system-config/KG/kg-report-card`,
                title: 'Report.Cards',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'kg-report-card-withdraw',
                path: `${APP_PREFIX_PATH}/grading-system-config/KG/kg-withdraw-certificates`,
                title: 'Report.Cards.withdraw',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'kg-honor-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/KG/kg-honor-students`,
                title: 'Honor.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'kg-fail-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/KG/kg-fail-students`,
                title: 'Fail.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
          {
            key: 'elmentarySchool',
            path: `${APP_PREFIX_PATH}/grading-system-config`,
            title: 'Elmentary.School',
            icon: '',
            breadcrumb: false,
            parentKey: 'grading-system-config',
            submenu: [
              {
                key: 'elmentary-report-card',
                path: `${APP_PREFIX_PATH}/grading-system-config/elmentary/elmentary-report-card`,
                title: 'Report.Cards',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'elmentary-withdraw-certificates',
                path: `${APP_PREFIX_PATH}/grading-system-config/elmentary/elmentary-withdraw-certificates`,
                title: 'Report.Cards.withdraw',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'elmentary-honor-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/elmentary/elmentary-honor-students`,
                title: 'Honor.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'elmentary-fail-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/elmentary/elmentary-fail-students`,
                title: 'Fail.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'elmentary-weak-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/elmentary/elmentary-weak-students`,
                title: 'Weak.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'elmentary-absent-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/elmentary/elmentary-absent-students`,
                title: 'Absent.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'elmentary-more-ninety-percent',
                path: `${APP_PREFIX_PATH}/grading-system-config/elmentary/elmentary-more-ninety-percent`,
                title: 'Stud_With_More_Ninety_Percent',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'mark-statistics',
                path: `${APP_PREFIX_PATH}/grading-system-config/mark-statistics?grade_type_id=4`,
                title: 'Mark_Statistics',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'student-marks',
                path: `${APP_PREFIX_PATH}/grading-system-config/student-marks?grade_type_id=4`,
                title: 'Student_Marks',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
          {
            key: 'middleSchool',
            path: `${APP_PREFIX_PATH}/grading-system-config`,
            title: 'Middle.School',
            icon: '',
            breadcrumb: false,
            parentKey: 'grading-system-config',
            submenu: [
              {
                key: 'middle-report-card',
                path: `${APP_PREFIX_PATH}/grading-system-config/middle/middle-report-card`,
                title: 'Report.Cards',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'middle-withdraw-certificates',
                path: `${APP_PREFIX_PATH}/grading-system-config/middle/middle-withdraw-certificates`,
                title: 'Report.Cards.withdraw',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'middle-honor-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/middle/middle-honor-students`,
                title: 'Honor.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'middle-fail-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/middle/middle-fail-students`,
                title: 'Fail.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'middle-weak-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/middle/middle-weak-students`,
                title: 'Weak.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'middle-absent-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/middle/middle-absent-students`,
                title: 'Absent.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'middle-more-ninety-percent',
                path: `${APP_PREFIX_PATH}/grading-system-config/middle/middle-more-ninety-percent`,
                title: 'Stud_With_More_Ninety_Percent',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'mark-statistics',
                path: `${APP_PREFIX_PATH}/grading-system-config/mark-statistics?grade_type_id=3`,
                title: 'Mark_Statistics',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'student-marks',
                path: `${APP_PREFIX_PATH}/grading-system-config/student-marks?grade_type_id=3`,
                title: 'Student_Marks',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
          {
            key: 'highSchool',
            path: `${APP_PREFIX_PATH}/grading-system-config`,
            title: 'High.School',
            icon: '',
            breadcrumb: false,
            parentKey: 'grading-system-config',
            submenu: [
              {
                key: 'high-report-card',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/high-report-card`,
                title: 'Report.Cards',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'high-withdraw-certificates',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/high-withdraw-certificates`,
                title: 'Report.Cards.withdraw',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'high-honor-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/high-honor-students`,
                title: 'Honor.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'high-weak-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/high-weak-students`,
                title: 'Weak.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'high-fail-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/high-fail-students`,
                title: 'Fail.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'high-absent-students',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/high-absent-students`,
                title: 'Absent.Students',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'apply-student-track',
                path: `${APP_PREFIX_PATH}/grading-system-config/apply-student-track`,
                title: 'Apply.Student.Track',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'old-year-grades',
                path: `${APP_PREFIX_PATH}/grading-system-config/marks/old-year-grades`,
                title: 'Old.Year.Grades',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'cumulative-gpa',
                path: `${APP_PREFIX_PATH}/grading-system-config/marks/cumulative-gpa`,
                title: 'Cumulative.GPA',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'major',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/major`,
                title: 'Major',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'grades-graduation-date',
                path: `${APP_PREFIX_PATH}/grading-system-config/grades-graduation-date`,
                title: 'Academic.graduation_date',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'transcript',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/transcript`,
                title: 'Transcript',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'graduation-certification',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/graduation-certification`,
                title: 'Graduation.Certification',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'high-reports',
                path: `${APP_PREFIX_PATH}/grading-system-config/high/high-reports`,
                title: 'High.Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'mark-statistics',
                path: `${APP_PREFIX_PATH}/grading-system-config/mark-statistics?grade_type_id=2`,
                title: 'Mark_Statistics',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'student-marks',
                path: `${APP_PREFIX_PATH}/grading-system-config/student-marks?grade_type_id=2`,
                title: 'Student_Marks',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
        ],
      },
      {
        key: 'human-resources',
        path: `${APP_PREFIX_PATH}/apps/`,
        title: 'human.resources',
        icon: BulbOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'add-employee',
            path: `${APP_PREFIX_PATH}/human-resource/add-employee`,
            title: 'add.employee',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-application',
            path: `${APP_PREFIX_PATH}/human-resource/employee-application`,
            title: 'LP.Applications',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-list',
            path: `${APP_PREFIX_PATH}/human-resource/employee-list`,
            title: 'employee.list',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'experience',
            path: `${APP_PREFIX_PATH}/human-resource/experience`,
            title: 'ExperienceYears',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'leaved-employee-list',
            path: `${APP_PREFIX_PATH}/human-resource/leaved-employee-list`,
            title: 'leaved.employee.list',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-dynamic-report-print',
            path: `${APP_PREFIX_PATH}/dynamic-report/employee-dynamic-report-print`,
            title: 'Dynamic.Reports.For.Employees',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'request-list',
            path: `${APP_PREFIX_PATH}/human-resource/request-list`,
            title: 'Warnings.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'absent-request-list',
            path: `${APP_PREFIX_PATH}/human-resource/absent-request-list`,
            title: 'Absent.Request.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'sickness-request-list',
            path: `${APP_PREFIX_PATH}/human-resource/sickness-request-list`,
            title: 'Sickness.Request.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'leave-request-list',
            path: `${APP_PREFIX_PATH}/human-resource/leave-request-list`,
            title: 'Leave.Permission.Request.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'late-request-list',
            path: `${APP_PREFIX_PATH}/human-resource/late-request-list`,
            title: 'Late.Request.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'punishment-request-list',
            path: `${APP_PREFIX_PATH}/human-resource/punishment-request-list`,
            title: 'Punishment.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'paid-vacation-request-list',
            path: `${APP_PREFIX_PATH}/human-resource/paid-vacation-request-list`,
            title: 'Paid.Vacation.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'periodic-vacation-request-list',
            path: `${APP_PREFIX_PATH}/human-resource/periodic-vacation-request-list`,
            title: 'Periodic.Vacation.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'comprehensive-employee-reports',
            path: `${APP_PREFIX_PATH}/human-resource/comprehensive-employee-reports`,
            title: 'Comprehensive.Reports',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'comprehensive-employee-reports-detailed',
            path: `${APP_PREFIX_PATH}/human-resource/comprehensive-employee-reports-detailed`,
            title: 'Comprehensive.Reports.Detailed',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'absent-request-report-list-dropdown',
            // path: `${APP_PREFIX_PATH}/human-resource/absent-request-report-list`,
            title: 'Request.Reports',
            icon: '',
            breadcrumb: true,
            submenu: [
              {
                key: 'request-report-list',
                path: `${APP_PREFIX_PATH}/human-resource/request-report-list`,
                title: 'Warning/Notice Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'absent-request-report-list',
                path: `${APP_PREFIX_PATH}/human-resource/absent-request-report-list?request_type_ids=8`,
                title: 'Absent.Request.Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'absent-request-report-list',
                path: `${APP_PREFIX_PATH}/human-resource/absent-request-report-list?request_type_ids=10`,
                title: 'Late.Request.Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'absent-request-report-list',
                path: `${APP_PREFIX_PATH}/human-resource/absent-request-report-list?request_type_ids=9`,
                title: 'Leave.Request.Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'absent-request-report-list',
                path: `${APP_PREFIX_PATH}/human-resource/absent-request-report-list?request_type_ids=7`,
                title: 'Sickness.Request.Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'absent-request-report-list',
                path: `${APP_PREFIX_PATH}/human-resource/absent-request-report-list?request_type_ids=17`,
                title: 'Punishment.Request.Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'absent-request-report-list',
                path: `${APP_PREFIX_PATH}/human-resource/absent-request-report-list?request_type_ids=18`,
                title: 'Paid.Vacation.Request.Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'absent-request-report-list',
                path: `${APP_PREFIX_PATH}/human-resource/absent-request-report-list?request_type_ids=19`,
                title: 'Periodic.Vacation.Request.Reports',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
          {
            key: 'print-modules',
            path: `${APP_PREFIX_PATH}/human-resource/print-modules`,
            title: 'Print.Modules',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'departements-reports',
            path: `${APP_PREFIX_PATH}/human-resource/departements-reports`,
            title: 'Departements.Reports',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-vacations',
            path: `${APP_PREFIX_PATH}/human-resource/employee-vacations-tabs`,
            title: 'Employee.Vacations',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-vacations-list',
            path: `${APP_PREFIX_PATH}/human-resource/employee-vacations-list`,
            title: 'Employee.Vacations.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-vacations-set',
            path: `${APP_PREFIX_PATH}/human-resource/employee-vacations-set`,
            title: 'Employee.Vacations.Set',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'login-history',
            path: `${APP_PREFIX_PATH}/human-resource/login-history`,
            title: 'Login_History',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'db-history',
            path: `${APP_PREFIX_PATH}/human-resource/db-history`,
            title: 'Database_History',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-logs',
            path: `${APP_PREFIX_PATH}/human-resource/employee-logs`,
            title: 'Employee Logs',
            icon: '',
            breadcrumb: false,
            submenu: [],
          }
        ],
      },
      {
        key: 'lms-roles',
        path: `${APP_PREFIX_PATH}/`,
        title: 'lms.roles',
        icon: BulbOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'school-calender',
            path: `${APP_PREFIX_PATH}/lms-roles/school-calender`,
            title: 'lms.roles.school.calender',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'time-table',
            path: `${APP_PREFIX_PATH}/lms-roles/time-table`,
            title: 'lms.roles.time.table',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'school-announcement-list',
            path: `${APP_PREFIX_PATH}/lms-roles/school-announcement/school-announcement-list`,
            title: 'lms.roles.school.announcement',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'course-plan-list',
            path: `${APP_PREFIX_PATH}/lms-roles/course-plan/course-plan-list`,
            title: 'lms.roles.course.plan',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'lock',
            path: `${APP_PREFIX_PATH}/lms-roles/lock-reset-account/lock`,
            title: 'lms.roles.lock.reset.account.lock',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'lock-certificate',
            path: `${APP_PREFIX_PATH}/lms-roles/lock-reset-account/lock-certificate`,
            title: 'lms.roles.lock.reset.account.lock.certificate',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'reset',
            path: `${APP_PREFIX_PATH}/lms-roles/lock-reset-account/reset`,
            title: 'lms.roles.lock.reset.account.reset',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'short-report-list',
            path: `${APP_PREFIX_PATH}/lms-roles/short-report/short-report-list`,
            title: 'lms.roles.short.report',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'sending-sms-history',
            path: `${APP_PREFIX_PATH}/lms-roles/sending-sms/sending-sms-history`,
            title: 'lms.roles.school.sending.sms',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'sending-notifications-history',
            path: `${APP_PREFIX_PATH}/lms-roles/sending-notifications/sending-notifications-history`,
            title: 'lms.roles.school.sending.notifications',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'school-gallery',
            path: `${APP_PREFIX_PATH}/lms-roles/school-gallery`,
            title: 'lms.roles.school.gallery',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'notifications-reports',
            path: `${APP_PREFIX_PATH}/lms-roles/notifications-reports`,
            title: 'Notifications.Reports',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'send-sms',
            path: `${APP_PREFIX_PATH}/lms-roles/send-sms`,
            title: 'Send_Sms',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'notifications-by-teacher',
            path: `${APP_PREFIX_PATH}/lms-roles/notifications-by-teacher`,
            title: 'Notification_By_Teacher',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'inbox',
            path: `${APP_PREFIX_PATH}/lms-roles/mail/inbox`,
            title: 'sidenav_mail',
            icon: '',
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'student-credentials',
            path: `${APP_PREFIX_PATH}/lms-roles/student-credentials`,
            title: 'Student_Credentials',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'studentAffairs',
        path: `${APP_PREFIX_PATH}/apps/`,
        title: 'student.affairs',
        icon: UsergroupDeleteOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'student-registration',
            path: `${APP_PREFIX_PATH}/student-affairs/student-registration`,
            title: 'student.registration',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-transfar',
            path: `${APP_PREFIX_PATH}/student-affairs/student-transfar`,
            title: 'student.transfar',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-list',
            path: `${APP_PREFIX_PATH}/student-affairs/student-list`,
            title: 'student.list',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'students',
            path: `${APP_PREFIX_PATH}/student-affairs/students`,
            title: 'Registered_Students',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'registered-student-list',
            path: `${APP_PREFIX_PATH}/student-affairs/registered-student-list`,
            title: 'registered.student.list',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'new-students',
            path: `${APP_PREFIX_PATH}/student-affairs/new-students`,
            title: 'New_Students',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'school-left-student-list',
            path: `${APP_PREFIX_PATH}/student-affairs/school-left-student-list`,
            title: 'Left.School',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'parent-list',
            path: `${APP_PREFIX_PATH}/student-affairs/parent-list`,
            title: 'parent.list',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-dynamic-report-print',
            path: `${APP_PREFIX_PATH}/dynamic-report/student-dynamic-report-print`,
            title: 'Dynamic.Reports.For.Students',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'students-statistics',
            path: `${APP_PREFIX_PATH}/student-affairs/students-statistics`,
            title: 'Students.Statistics',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'nationality-statistics',
            path: `${APP_PREFIX_PATH}/student-affairs/nationality-statistics`,
            title: 'Nationaltiy.Statitsics',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'set-student-code',
            path: `${APP_PREFIX_PATH}/student-affairs/set-student-code`,
            title: 'Set_Student_Code',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'grades-end-stage-date',
            path: `${APP_PREFIX_PATH}/student-affairs/grades-end-stage-date`,
            title: 'Academic_End_Stage',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'kg-interview-list',
            path: `${APP_PREFIX_PATH}/student-affairs/kg-interview-list`,
            title: 'Kindergarten_Interview',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-status',
            path: `${APP_PREFIX_PATH}/student-affairs/student-status`,
            title: 'Medical.Issues',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-logs',
            path: `${APP_PREFIX_PATH}/student-affairs/student-logs`,
            title: 'Student Logs',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'special-health-students',
            path: `${APP_PREFIX_PATH}/student-affairs/special-health-students`,
            title: 'SpecialHealthStudents',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'school-schedule',
        path: `${APP_PREFIX_PATH}/apps/`,
        title: 'School_Schedule',
        icon: GrSchedule,
        breadcrumb: true,
        submenu: [
          {
            key: 'periods',
            path: `${APP_PREFIX_PATH}/school-schedule/periods`,
            title: 'Periods',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'absence-list',
            path: `${APP_PREFIX_PATH}/school-schedule/absence-list`,
            title: 'Attendance',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'class-schedule',
            path: `${APP_PREFIX_PATH}/school-schedule/class-schedule`,
            title: 'Class_Schedule',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'teacher-schedule',
            path: `${APP_PREFIX_PATH}/school-schedule/teacher-schedule`,
            title: 'Teacher_Schedule',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'absence-report',
            path: `${APP_PREFIX_PATH}/school-schedule/absence-report`,
            title: 'Absent.Request.Reports',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'view-attendance',
            path: `${APP_PREFIX_PATH}/school-schedule/view-attendance`,
            title: 'Daily_Attendance',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'viewMonthly-attendance',
            path: `${APP_PREFIX_PATH}/school-schedule/viewMonthly-attendance`,
            title: 'view_monthly_attendance',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'attendance-entry',
            path: `${APP_PREFIX_PATH}/school-schedule/attendance-entry`,
            title: 'Attendance_Entry',
            icon: '',
            breadcrumb: false,
            submenu: [],
          }
        ],
      },
      {
        key: 'reception-role',
        path: `${APP_PREFIX_PATH}/apps/`,
        title: 'Recieption.Department',
        icon: UsergroupDeleteOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'requests',
            path: `${APP_PREFIX_PATH}/request/requests`,
            title: 'Requests.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'requests_report',
            path: `${APP_PREFIX_PATH}/request/requests_report`,
            title: 'Request.Report.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'student-account-area',
        path: `${APP_PREFIX_PATH}/`,
        title: 'Student.Section.Area',
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'student-apply-bulk-discount',
            path: `${APP_PREFIX_PATH}/student-account-area/student-apply-bulk-discount`,
            title: 'Apply.Bulk.Discount.For.Student',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-account-statement-list',
            path: `${APP_PREFIX_PATH}/student-account-area/student-account-statement-list`,
            title: 'Student.Account.Statement',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },

          {
            key: 'student-account-statement-review-requests',
            path: `${APP_PREFIX_PATH}/student-account-area/student-account-statement-review-requests`,
            title: 'Student.Account.Statement.Review.Requests',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'zero-paid-sudent',
            path: `${APP_PREFIX_PATH}/student-account-area/zero-paid-sudent`,
            title: 'Zero_Paid_Student',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'brothers-account-statement',
            path: `${APP_PREFIX_PATH}/student-account-area/brothers-account-statement`,
            title: 'Brothers.Account.Statement',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "student-apply-bulk-fees",
          //   path: `${APP_PREFIX_PATH}/student-account-area/student-apply-bulk-fees`,
          //   title: "Apply.Bulk.Fees.For.Student",
          //   icon: DashboardOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: 'pre-registered-student-list',
            path: `${APP_PREFIX_PATH}/student-account-area/pre-registered-student-list`,
            title: 'Pre.Registration.For.Next.Academic.Year',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-apply-bulk-fees-new-year',
            path: `${APP_PREFIX_PATH}/student-account-area/student-apply-bulk-fees-new-year`,
            title: 'Apply.Bulk.Fees.For.Student.New.Year',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-transfer-next-year',
            path: `${APP_PREFIX_PATH}/student-account-area/student-transfer-next-year`,
            icon: DashboardOutlined,
            title: 'student.transfar.next.year',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'print-grade-fees',
            path: `${APP_PREFIX_PATH}/student-account-area/print-grade-fees`,
            title: 'Print.Grade.Fees',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-pending-amount-SMS',
            path: `${APP_PREFIX_PATH}/student-account-area/student-pending-amount-SMS`,
            title: 'Send.Pending.Amount.Fees.SMS',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'print-student-fees',
            path: `${APP_PREFIX_PATH}/student-account-area/print-student-fees`,
            title: 'Print.Student.Fees',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'canceled-receipts',
            path: `${APP_PREFIX_PATH}/student-account-area/canceled-receipts`,
            title: 'Canceled.Receipts',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'review-payment-history',
            path: `${APP_PREFIX_PATH}/student-account-area/review-payment-history`,
            title: 'Review_Payment_History',
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'webpage',
        path: `${APP_PREFIX_PATH}/webpage`,
        title: 'Web.Pages',
        icon: RiPagesLine,
        breadcrumb: true,
        submenu: [
          {
            key: 'home-webpage',
            path: `${APP_PREFIX_PATH}/webpage/home-webpage`,
            title: 'LP.Home.Page',
            icon: '',
            breadcrumb: false,
            submenu: [
              {
                key: 'video-bannar-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/video-bannar-sec`,
                title: 'LP.Video.Banner.Sec',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'about-us-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/about-us-sec`,
                title: 'LP.About.Us.Sec',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'intro-ap-courses-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/intro-ap-courses-sec`,
                title: 'Intro Ap Courses',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'ap-courses-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/ap-courses-sec`,
                title: 'Ap Courses',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'intro-pre-ap-courses-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/intro-pre-ap-courses-sec`,
                title: 'Intro Pre Ap Courses',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'pre-ap-courses-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/pre-ap-courses-sec`,
                title: 'Ap Pre Courses',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'curriclum-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/curriculum-sec`,
                title: 'LP.Curriculum.Sec',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'latest-update-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/latest-update-sec`,
                title: 'LP.Latest.Update.Sec',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'gallery-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/gallery-sec`,
                title: 'LP.Gallery.Sec',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'map-sec',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/map-sec`,
                title: 'LP.Map.Sec',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
              {
                key: 'sponsor',
                path: `${APP_PREFIX_PATH}/webpage/home-webpage/sponsor`,
                title: 'School_Sponsor',
                icon: '',
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
          {
            key: 'school-stages',
            path: `${APP_PREFIX_PATH}/webpage/internal-webpage/school-stages`,
            title: 'school.grade.types',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'maker-space',
            path: `${APP_PREFIX_PATH}/webpage/internal-webpage/maker-space`,
            title: 'maker space',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'courses',
            path: `${APP_PREFIX_PATH}/webpage/internal-webpage/courses`,
            title: 'Courses',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'about-us-page',
            path: `${APP_PREFIX_PATH}/webpage/about-us-page`,
            title: 'LP.About.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'manager-words-page',
            path: `${APP_PREFIX_PATH}/webpage/manager-words-page`,
            title: 'LP.Manager.Words',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'curriculums-page',
            path: `${APP_PREFIX_PATH}/webpage/curriculums-page`,
            title: 'LP.Curriclum.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'admission-page',
            path: `${APP_PREFIX_PATH}/webpage/admission-page`,
            title: 'LP.Admission.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'application-page',
            path: `${APP_PREFIX_PATH}/webpage/application-page`,
            title: 'LP.Application.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'stud-application-page',
            path: `${APP_PREFIX_PATH}/webpage/stud-application-page`,
            title: 'LP.Stud.Application.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'application-appointment',
            path: `${APP_PREFIX_PATH}/webpage/application-appointment`,
            title: 'LP.Stud.Application.Appointment',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'sms-history',
            path: `${APP_PREFIX_PATH}/webpage/sms-history`,
            title: 'LP.Sms.History',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'technology-page',
            path: `${APP_PREFIX_PATH}/webpage/technology-page`,
            title: 'LP.Tech.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'contact-page',
            path: `${APP_PREFIX_PATH}/webpage/contact-page`,
            title: 'LP.Contact.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'gallery-page',
            path: `${APP_PREFIX_PATH}/webpage/gallery-page`,
            title: 'LP.Gallery.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'album-page',
            path: `${APP_PREFIX_PATH}/webpage/album-page`,
            title: 'LP.Album.Page',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'clinics',
        path: `${APP_PREFIX_PATH}/clinics`,
        title: 'Clinics',
        icon: FaStethoscope,
        breadcrumb: true,
        submenu: [
          {
            key: 'medical-definition',
            path: `${APP_PREFIX_PATH}/clinics/medical-definition`,
            title: 'Basic.Data',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'medicine',
            path: `${APP_PREFIX_PATH}/clinics/medicine`,
            title: 'Medicine',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },

          {
            key: 'medical-record',
            path: `${APP_PREFIX_PATH}/clinics/medical-record`,
            title: 'Medical.Record',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-medical-profile',
            path: `${APP_PREFIX_PATH}/clinics/student-medical-profile`,
            title: 'Student.Medical.Profile',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'medical-leave-list',
            path: `${APP_PREFIX_PATH}/clinics/medical-leave-list`,
            title: 'Medical.Leave.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'daily-student-list',
            path: `${APP_PREFIX_PATH}/clinics/daily-student-list`,
            title: 'Daily.Student.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'library',
        path: `${APP_PREFIX_PATH}/library`,
        title: 'Library',
        icon: IoLibraryOutline,
        breadcrumb: true,
        submenu: [
          {
            key: 'Library-definition',
            path: `${APP_PREFIX_PATH}/library/Library-definition`,
            title: 'Library.Definition',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'borrow-form',
            path: `${APP_PREFIX_PATH}/library/borrow-form`,
            title: 'Add.Borrow',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'book-record',
            path: `${APP_PREFIX_PATH}/library/book-record`,
            title: 'Book.Record',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-borrowed-not-returned-books',
            path: `${APP_PREFIX_PATH}/library/employee-borrowed-not-returned-books`,
            title: 'Employee.Borrowed.Books',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'student-borrowed-not-returned-books',
            path: `${APP_PREFIX_PATH}/library/student-borrowed-not-returned-books`,
            title: 'Student.Borrowed.Books',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'Library-Profile',
            path: `${APP_PREFIX_PATH}/library/Library-Profile`,
            title: 'Library.Profile',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'employee-library-list',
            path: `${APP_PREFIX_PATH}/library/employee-library-list`,
            title: 'Employee.Library.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'damage-books',
            path: `${APP_PREFIX_PATH}/library/damage-books`,
            title: 'Damaged.Books',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'store',
        path: `${APP_PREFIX_PATH}/store`,
        title: 'store.definition',
        icon: FaStoreAlt,
        breadcrumb: true,
        submenu: [
          {
            key: 'store-definition',
            path: `${APP_PREFIX_PATH}/store/store-definition`,
            title: 'store.definition',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'store-books',
            path: `${APP_PREFIX_PATH}/store/store-books`,
            title: 'Store.Book',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'purchase-books-list',
            path: `${APP_PREFIX_PATH}/store/purchase-books/purchase-books-list`,
            title: 'Purchase.Books.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'sell',
            path: `${APP_PREFIX_PATH}/store/sell`,
            title: 'Sell.Books',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'books-delivred-to-teacher',
            path: `${APP_PREFIX_PATH}/store/books-delivred-to-teacher`,
            title: 'Teacher.Deliverd.Books',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'books-delivred-to-student',
            path: `${APP_PREFIX_PATH}/store/books-delivred-to-student`,
            title: 'Student.Deliverd.Books',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'books-received-from-student',
            path: `${APP_PREFIX_PATH}/store/books-received-from-student`,
            title: 'Student.Received.Books',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'books-received-from-teacher',
            path: `${APP_PREFIX_PATH}/store/books-received-from-teacher`,
            title: 'Teacher.Received.Books',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'store-report-purchase',
            path: `${APP_PREFIX_PATH}/store/store-report-purchase`,
            title: 'Store.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'store-report-sell',
            path: `${APP_PREFIX_PATH}/store/store-report-sell`,
            title: 'Store.Report.Sell',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'store-report-receive-book',
            path: `${APP_PREFIX_PATH}/store/store-report-receive-book`,
            title: 'Store.Report.Book.Receive',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'available-books-reports',
            path: `${APP_PREFIX_PATH}/store/available-books-reports`,
            title: 'Available_Books_Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'clothes',
        path: `${APP_PREFIX_PATH}/clothes`,
        title: 'Clothes',
        icon: GiPoloShirt,
        breadcrumb: true,
        submenu: [
          {
            key: 'clothes-definition',
            path: `${APP_PREFIX_PATH}/clothes/clothes-definition`,
            title: 'Clothes.Store',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'clothes-list',
            path: `${APP_PREFIX_PATH}/clothes/clothes-list`,
            title: 'Clothes.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'selled-clothes',
            path: `${APP_PREFIX_PATH}/clothes/selled-clothes`,
            title: 'Selling.Clothes.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'purchased-clothes',
            path: `${APP_PREFIX_PATH}/clothes/purchased-clothes`,
            title: 'Purchased.Clothes.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'refunded-clothes',
            path: `${APP_PREFIX_PATH}/clothes/refunded-clothes`,
            title: 'Refunded.Clothes.List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'proposal-request-list',
            path: `${APP_PREFIX_PATH}/clothes/proposal-request-list`,
            title: 'Proposal.Request',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'daily-collector-report',
            path: `${APP_PREFIX_PATH}/clothes/daily-collector-report`,
            title: 'Daily.Collector.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'detailed-collector-report',
            path: `${APP_PREFIX_PATH}/clothes/detailed-collector-report`,
            title: 'Detailed.Collector.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'clothe-sold-return-report',
            path: `${APP_PREFIX_PATH}/clothes/clothe-sold-return-report`,
            title: 'Clothe.Sold.Return.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'inventory-clothes-report',
            path: `${APP_PREFIX_PATH}/clothes/inventory-clothes-report`,
            title: 'Inventory.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'distributers-clothes-report',
            path: `${APP_PREFIX_PATH}/clothes/distributers-clothes-report`,
            title: 'Distributers.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'product-movment-report',
            path: `${APP_PREFIX_PATH}/clothes/product-movment-report`,
            title: 'Product.Movemnt.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'account-tree',
        path: `${APP_PREFIX_PATH}/account-tree`,
        title: 'Account.Tree',
        icon: CgListTree,
        breadcrumb: true,
        submenu: [
          {
            key: 'account-tree-definition',
            path: `${APP_PREFIX_PATH}/account-tree/account-tree-definition`,
            title: 'Account.Tree.Definintion',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'cost-centers',
            path: `${APP_PREFIX_PATH}/account-tree/cost-centers`,
            title: 'Cost.Center',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'cheque-book',
            path: `${APP_PREFIX_PATH}/account-tree/cheque-book`,
            title: 'Cheque_Book',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'journal-vouchers',
            path: `${APP_PREFIX_PATH}/account-tree/journal-vouchers`,
            title: 'Invoice.Entry',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'bank-voucher',
            path: `${APP_PREFIX_PATH}/account-tree/bank-voucher`,
            title: 'Bank_Voucher',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'cash-voucher',
            path: `${APP_PREFIX_PATH}/account-tree/cash-voucher`,
            title: 'Cash_Voucher',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'account-statment-report',
            path: `${APP_PREFIX_PATH}/account-tree/account-statment-report`,
            title: 'Account_Statment_Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'account-motion-report',
            path: `${APP_PREFIX_PATH}/account-tree/account-motion-report`,
            title: 'Account_Motion_Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'account-trial-report',
            path: `${APP_PREFIX_PATH}/account-tree/account-trial-report`,
            title: 'Account_Tarial_Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'revenue-expense',
            path: `${APP_PREFIX_PATH}/account-tree/revenue-expense`,
            title: 'Revenue_And_Expense_Statement',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'account-tree-report',
            path: `${APP_PREFIX_PATH}/account-tree/account-tree-report`,
            title: 'AccountTreeReport',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'cost-center-report',
            path: `${APP_PREFIX_PATH}/account-tree/cost-center-report`,
            title: 'CostCenterReport',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'account-reports',
            path: `${APP_PREFIX_PATH}/account-tree/trial-balance-report`,
            title: 'TrialBalanceReport',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'details-report',
            path: `${APP_PREFIX_PATH}/account-tree/details-report`,
            title: 'DetailsReport',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'review-requests',
            path: `${APP_PREFIX_PATH}/account-tree/review-requests`,
            title: 'ReviewRequests',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'school-supplies',
        path: `${APP_PREFIX_PATH}/school-supplies`,
        title: 'School_Supplies',
        icon: LiaPencilRulerSolid,
        breadcrumb: true,
        submenu: [
          {
            key: 'stationery-distributors',
            path: `${APP_PREFIX_PATH}/school-supplies/stationery-distributors`,
            title: 'Distributers',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'stationery-list',
            path: `${APP_PREFIX_PATH}/school-supplies/stationery-list`,
            title: 'Stationery_List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'purchased-stationery',
            path: `${APP_PREFIX_PATH}/school-supplies/purchased-stationery`,
            title: 'Purchased_Stationery_List',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'stationery-request',
            path: `${APP_PREFIX_PATH}/school-supplies/stationery-request`,
            title: 'Stationery_Request',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'stationery-proposal',
            path: `${APP_PREFIX_PATH}/school-supplies/stationery-proposal`,
            title: 'Purchase_Offers',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'stationery-daily-collector',
            path: `${APP_PREFIX_PATH}/school-supplies/stationery-daily-collector`,
            title: 'Daily.Collector.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'stationery-movement',
            path: `${APP_PREFIX_PATH}/school-supplies/stationery-movement`,
            title: 'Product.Movemnt.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'stationery-inventory',
            path: `${APP_PREFIX_PATH}/school-supplies/stationery-inventory`,
            title: 'Inventory.Report',
            icon: '',
            breadcrumb: false,
            submenu: [],
          }
          
        ]
      }
    ],
  },
];

const navigationConfig = [...dashboardNavTree];
export default navigationConfig;
